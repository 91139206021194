import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NbPopoverDirective, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbSearchService } from '@nebular/theme';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, interval, Observable } from 'rxjs';
import { LocalS } from '../../../auth/utils/Locstorage';
import { AnalyticsService } from '../../../pages/analytics/analytics.service';
import { UserData } from '../../../pages/users/userData';
import { DeviceInfo } from '../../../pages/devices/deviceInfo';
import { DOCUMENT, formatDate } from '@angular/common';
// import { AllAlertsComponent } from '../../../pages/dashboard/all-alerts/all-alerts.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  // componentList = AllAlertsComponent;  
 
  private destroy$: Subject<void> = new Subject<void>();
  devices: Observable<DeviceInfo[]>;

  selectedDevice = null;
  value = '';
  currentTheme = 'default';
  themes = [{ value: 'default', name: 'Light' }, { value: 'dark', name: 'Dark' }];

  user: any;
  userPictureOnly = true;
  userMenu = [{ title: 'Log Out' }];

  loading = false;
  currentAllDevices = [];

  tableAlerts = [];
  isShow = true;
  elem: any;

  constructor(private sidebarService: NbSidebarService, private menuService: NbMenuService, private themeService: NbThemeService,
    private searchService: NbSearchService,  
    @Inject(DOCUMENT) private document: any, private breakpointService: NbMediaBreakpointsService, public router: Router,
    private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.elem = document.documentElement;
    //this.devices = this.analyticsService.getAllDevices();

    this.analyticsService.getAllDevices().subscribe((data: DeviceInfo[]) => {
      this.loading = true;
      data.forEach(result => {
        this.currentAllDevices = [...this.currentAllDevices, { device_name: result.device_name, device_id: result.device_id }];    
      });     

      if (LocalS.getInstanceID() == "undefined" || LocalS.getInstanceID() == null || LocalS.getInstanceID() == "") {
        this.selectedDevice = this.currentAllDevices[0].device_id;
        this.analyticsService.currentDevice.next(this.selectedDevice);
        LocalS.saveInstanceID(this.selectedDevice);
      } else {
        this.selectedDevice = LocalS.getInstanceID();
        this.analyticsService.currentDevice.next(this.selectedDevice);
      }

      this.loading = false;
    });

    this.analyticsService.currentDevice.subscribe((data) => {
      this.selectedDevice = data;
    });

    this.searchService.onSearchSubmit().subscribe((data: any) => {
      this.value = data.term;
      this.GlobalSearch(this.value);
    });

    if (LocalS.getCurrentTheme() == null || LocalS.getCurrentTheme() == "") {
      this.currentTheme = this.themeService.currentTheme;
      LocalS.saveCurrentTheme(this.currentTheme);
    } else {
      this.currentTheme = LocalS.getCurrentTheme();
      this.themeService.changeTheme(this.currentTheme);
    }

    let currentUser = LocalS.getUsername();
    if (currentUser != null) {
      let input: UserData = new UserData();
      input.username = currentUser;
      this.user = input;
    }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange().pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      takeUntil(this.destroy$)).subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange().pipe(map(({ name }) => name), takeUntil(this.destroy$)).subscribe(
      themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((event) => {
      this.value = event.item.title;
      this.onItemSelection(event.item.title);
    });
  }
  
  openFullscreen() {
    this.isShow = false;
    this.toggleSidebar();
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  
  /* Close fullscreen */
  closeFullscreen() {
    this.isShow = true;
    this.toggleSidebar();
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  updateDisplayedData(data) {
    this.analyticsService.currentDevice.next(data);
    LocalS.saveInstanceID(data);
  }

  changeTheme(themeName: string) {
    LocalS.saveCurrentTheme(themeName);
    this.themeService.changeTheme(themeName);
  }

  onItemSelection(title) {
    if (title == "Log Out") {
      this.logout();
    // } else if (title == "Profile") {
    //   this.router.navigate(["/pages/users"]);
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    LocalS.removeInstanceID();
    LocalS.removeUser();
    this.router.navigate(["/auth/login"]);
  }

  GlobalSearch(searchval) {
    var s = searchval.toLowerCase();
    if (s == "log out" || searchval == "logout"  ) {
      this.logout();
    } else if (s == "monitoring") {
      this.router.navigate(["/pages/monitoring"]);
    } else if (s == "overview") {
      this.router.navigate(["/pages/dashboard"]);
    } else if (s == "analytics") {
      this.router.navigate(["/pages/analytics"]);
    } else if (s == "map") {
      this.router.navigate(["/pages/map"]);
    } else if (s == "global alerts" || s == "globalalerts") {
      this.router.navigate(["/pages/global-alerts"]);
    } else if (s == "reports") {
      this.router.navigate(["/pages/reports"]);
    } else if (s == "application") {
      this.router.navigate(["/pages/statistics/application"]);
    } else if (s == "interface") {
      this.router.navigate(["/pages/statistics/interface"]);
    } else if (s == "ipsectunnel" || s == "ipsec tunnel") {
      this.router.navigate(["/pages/statistics/ipsec-tunnel"]);
    }  else if (s == "mpsec overlay" || s == "mpsecoverlay" || s == "tunnel") {
      this.router.navigate(["/pages/statistics/tunnel"]);
    } else if (s == "mpsec qos" || s == "mpsecqos"  ) {
      this.router.navigate(["/pages/statistics/mpsec-qos"]);
    } else if (s == "qos" || s == "qos"  ) {
      this.router.navigate(["/pages/statistics/qos"]);
    } else if (s == "system") {
      this.router.navigate(["/pages/statistics/system"]);
    } else if (s == "wan-data-rate" || s == "wan data rate") {
      this.router.navigate(["/pages/statistics/wan-data-rate"]);
    } else if (s == "wan-data-rate" || s == "wan data rate") {
      this.router.navigate(["/pages/statistics/wan-data-rate"]);
    } else if (s == "dashboard" || s == "webfilterdashboard" || s == "webfilter dashboard") {
      this.router.navigate(["/pages/web-filter/dashboard"]);
    } else if (s == "categories" || s == "webfiltercategories" || s == "webfilter categories") {
      this.router.navigate(["/pages/web-filter/categories"]);
    } else if (s == "users" || s == "webfilteruser" || s == "webfilter user") {
      this.router.navigate(["/pages/web-filter/users"]);
    }  else if (s == "alerts") {
      this.router.navigate(["/pages/alerts"]);
    } else if (s == "devices") {
      this.router.navigate(["/pages/devices"]);
    } else if (s == "profile" || s == "fatpipe users" || s == "fatpipeusers") {
      this.router.navigate(["/pages/users"]);
    } else if (s == "about") {
      this.router.navigate(["/pages/help/about"]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
