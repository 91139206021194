import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Role } from '../models/role';
import { Router } from '@angular/router';
import { LocalS } from '../utils/Locstorage';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private hostname = window.location.host;
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  private LOGIN_URL = "https://" + this.hostname + "/login";

  constructor(private http: HttpClient, private _router: Router) { }

  login(username: string, password: string) {
    let token = LocalS.getToken();

    if (token == null || token == "") {
      this._router.navigate(['/auth/login']);
    }

    let userDetails = { 'username': username, 'password': LocalS.getEncPwd(password) };
    return this.http.post<any>(this.LOGIN_URL, userDetails, { headers: this.headers }).pipe(map(user => {
      if (user != null) {
        let response;
        let role = "";
        if (user['role'] == 0) {
          role = Role.Admin;
        } else {
          role = Role.User;
        }
        response = { ...user, ...{ username }, ...{ role } };
        LocalS.saveUser(response);
      }
      return user;
    }));
  }
}
