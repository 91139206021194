import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="copyright">© Copyright 2020 <b><a href="https://www.fatpipeinc.com/" target="_blank">FatPipe Networks</a></b></span>
  `,
})
export class FooterComponent {
}
