import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule, NbCardModule, NbLayoutModule, NbToastrModule, NbSpinnerModule } from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ErrorInterceptor } from '../auth/helpers';
import { AuthComponent } from './auth.component';
import { LoginProvider } from './services';
import { LoginComponent } from './login/login.component';

const COMPONENTS = [
  LoginComponent,
  AuthComponent
];

const ENTRY_COMPONENTS = [
  LoginComponent
];

const MODULES = [
  AuthRoutingModule,
  CommonModule,
  FormsModule,
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  RouterModule,
  NbAuthModule,
  NbCardModule,
  NbLayoutModule,
  NbSpinnerModule,
  NgbModule,
  ThemeModule.forRoot(),
  NbToastrModule.forRoot(),
  NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'fullName',
      }),
    ],
    forms: {
      validation: {
        password: {
          required: true,
          minLength: 4,
          maxLength: 32,
        },
        email: {
          required: false,
        },
        fullName: {
          required: true,
          minLength: 4,
          maxLength: 15,
        },
      },
    },
  }),
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  bootstrap: [LoginComponent],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        LoginProvider
      ],
    };
  }
}
