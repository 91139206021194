import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../services';
import { NbAuthService, NbLoginComponent } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';

/**
 * class responsible for handling ui for user authentication.
 */
@Component({
  selector: 'nbcust-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
  providers: [NbLoginComponent, NbToastrService]
})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  protected service: NbAuthService;
  protected options: {};
  redirectDelay: number = 500;
  showMessages: any;
  strategy: string;
  errors: string[];
  messages: string[];
  user: any;
  rememberMe: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private nblogin: NbLoginComponent, private toastrService: NbToastrService) {
    this.redirectDelay = nblogin.redirectDelay;
    this.showMessages = nblogin.showMessages;
    this.strategy = nblogin.strategy;
    this.errors = nblogin.errors;
    this.messages = nblogin.messages;
    this.user = nblogin.user;
    this.rememberMe = nblogin.rememberMe;
  }

  getConfigValue(key: string): any {
    return this.nblogin.getConfigValue(key);
  }

  ngOnInit() {
    // reset login status
    //this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.authenticationService.login(this.user.name, this.user.password).pipe(first()).subscribe(data => {
      this.router.navigate([this.returnUrl]);
    }, err => {
        this.toastrService.danger(err.error.message, "Login " + err.statusText, { icon: 'person', destroyByClick: true, duration: 5000, limit: 3 });
        this.loading = false;
        this.router.navigate([this.returnUrl]);
    });
  }
}
