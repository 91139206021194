import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalS } from '../utils/Locstorage';
import { AuthenticationService } from './authentication.service';
//import { User } from '../models';

/**
 * Class Responsible for intercepting all requests.
 * if request has user token allow to proceed to user dashbord
 * else divert request to authentication login page.
 */
@Injectable()
export class LoginInterceptor implements HttpInterceptor {

  constructor(private router: Router, private auth: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    let apiKey = LocalS.getToken();
    if (apiKey == null || apiKey == "") {
      this.router.navigate(['/auth/login']);
    }
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let response = event.body;
          if (response != null && typeof (response) != 'undefined') {
          }

        }
        return event;
      }
      ));
    return next.handle(request);
  }
}

export let LoginProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: LoginInterceptor,
  multi: true,
};
