//import { User } from "../models";
import * as SecureLS from 'secure-ls';
import MD5 from 'crypto-js/md5';
//import { UserData } from '../../pages/users/userData';

export class LocalS {
  private static secureStorage: SecureLS;

  public static saveUser(data: any) {
    let apiKey = data["fp-api-key"];
    let username = data["username"];
    let role = data["role"];

    if (this.secureStorage == null) {
      this.secureStorage = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'sKzYtZrdDNjYsYsFuzMdaFT8mqq63NFQzprNXSDpXxTyU8HK2twjkEgwhQg7mDTw3yp68vQPJRB2FcMj75qLDTN5tuqS63Qd8aC3ZXxRC46Gv9qr7ghh9sUUHqWHCyas' });
    }
    this.secureStorage.set("token", apiKey);
    this.secureStorage.set("user", username);
    this.secureStorage.set("role", role);

    return Promise.resolve();
  }

  public static removeUser() {
    this.secureStorage.remove("token");
    this.secureStorage.remove("user");
    this.secureStorage.remove("role");
  }

  public static getToken() {
    if (this.secureStorage == null) {
      this.secureStorage = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'sKzYtZrdDNjYsYsFuzMdaFT8mqq63NFQzprNXSDpXxTyU8HK2twjkEgwhQg7mDTw3yp68vQPJRB2FcMj75qLDTN5tuqS63Qd8aC3ZXxRC46Gv9qr7ghh9sUUHqWHCyas' });
    }
    return this.secureStorage.get("token");
  }

  public static getUsername() {
    if (this.secureStorage == null) {
      this.secureStorage = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'sKzYtZrdDNjYsYsFuzMdaFT8mqq63NFQzprNXSDpXxTyU8HK2twjkEgwhQg7mDTw3yp68vQPJRB2FcMj75qLDTN5tuqS63Qd8aC3ZXxRC46Gv9qr7ghh9sUUHqWHCyas' });
    }
    return this.secureStorage.get("user");
  }

  public static getRole() {
    if (this.secureStorage == null) {
      this.secureStorage = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'sKzYtZrdDNjYsYsFuzMdaFT8mqq63NFQzprNXSDpXxTyU8HK2twjkEgwhQg7mDTw3yp68vQPJRB2FcMj75qLDTN5tuqS63Qd8aC3ZXxRC46Gv9qr7ghh9sUUHqWHCyas' });
    }
    return this.secureStorage.get("role");
  }

  //public static getUser(json: any): User {
  //  json = JSON.parse(json);
  //  if (json == null || json == '' || typeof (json) == 'undefined')
  //    return null;

  //  let username = this.getVal(json, 'username');
  //  let role = this.getVal(json, 'role');
  //  let token = this.getVal(json, 'fp-api-key');
  //  let status = this.getVal(json, 'status');
  //  let user: User = new User(username, token, role, status);
  //  return user;
  //}

  //public static getLoginUser(json: any): UserData {
  //  json = JSON.parse(json);
  //  if (json == null || json == '' || typeof (json) == 'undefined')
  //    return null;

  //  let username = this.getVal(json, 'username');

  //  let role = Number.parseInt(this.getVal(json, 'role'));
  //  let user: UserData = new UserData();

  //  user.username = username;
  //  user.role = role;

  //  return user;
  //}

  //private static getVal(json: string, key: string): string {
  //  if (json == null || json == '' || typeof (json) == 'undefined') {
  //    return null;
  //  }

  //  let value = json[key];
  //  return value != null ? value : null;
  //}

  /* private static checkExpiry(user: any) {
       return new Promise((resolve) => {
           if (user) {
               const dataObj = JSON.parse(user);
               let exp = moment(dataObj.expiry);
               let cur = moment();
               let diff = exp.diff(cur);//hours
               let duration = moment.duration(diff);

               let days = duration.days();
               let hours = duration.hours();
               let min = duration.minutes();

               if (dataObj.expiry && (days <= 0 && hours <= 0 && min < 0)) {

                   this.removeUser();
                   delete dataObj.expiry;
                   resolve(dataObj);
               }
           }
           resolve();
       });
   }*/

  public static getEncPwd(passwd: string) {
    return MD5(passwd).toString();
  }

  public static saveInstanceID(instanceID: string) {
    localStorage.setItem("instance_id", instanceID);
  }

  public static getInstanceID() {
    return localStorage.getItem("instance_id");
  }

  public static removeInstanceID() {
    return localStorage.removeItem("instance_id");
  }

  // @param data - set current theme
  public static saveCurrentTheme(theme: any) {
    localStorage.setItem("currentTheme", theme);
  }

  // get current selected theme
  public static getCurrentTheme() {
    return localStorage.getItem("currentTheme");
  }

  // remove current theme on load.
  public static removeCurrentTheme() {
    return localStorage.removeItem("currentTheme");
  }
}
