import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-auth-app',
  templateUrl: 'auth.component.html',
})
export class AuthComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}
