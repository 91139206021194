<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle"><nb-icon icon="menu-2-outline"></nb-icon></a>
    <a class="logo" href="#" (click)="navigateHome()">EnterpriseView</a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action *ngIf="router.url.includes('/pages/analytics') || router.url.includes('/pages/statistics')">
      <ng-select class="custom" [items]="currentAllDevices" bindLabel="device_name" bindValue="device_id" [(ngModel)]="selectedDevice" (change)="updateDisplayedData(selectedDevice)" (clear)="updateDisplayedData('undefined')" placeholder="Select a FatPipe...">
      </ng-select>
    </nb-action>

    <!-- <nb-action>
      <div class="popover-container">
        <nb-icon icon="alert-triangle-outline" nbTooltipPlacement="bottom" [nbPopover]="componentList"></nb-icon>
      </div>
    </nb-action> -->

    <nb-action *ngIf="isShow" (click)="openFullscreen()">
      <nb-icon nbTooltip="View FullScreen" nbTooltipStatus="primary" icon="expand-outline" icon-height="50em" icon-width="50em"></nb-icon>
    </nb-action>
    <nb-action *ngIf="!isShow" (click)="closeFullscreen()">
      <nb-icon nbTooltip="Exit FullScreen" nbTooltipStatus="primary" icon="collapse-outline" icon-height="50em" icon-width="50em"></nb-icon>
    </nb-action>

    <nb-action>
      <nb-search nbTooltip="Search" nbTooltipStatus="primary" type="column-curtain"></nb-search>
    </nb-action>

    <nb-action class="control-item">
      <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary" size="small">
        <nb-option *ngFor="let theme of themes" [value]="theme.value">{{ theme.name }}</nb-option>
      </nb-select>
    </nb-action>

    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" onlyPicture="true" [name]="user?.username"></nb-user>
    </nb-action>
  </nb-actions>
</div>