import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { JSONutils } from '../../auth/utils/JSONUtils';

import { DeviceInfo } from '../devices/deviceInfo';
import { TextAlert } from './device-alerts/textAlerts';
import { SystemUptime } from './device-info/systemUptime';
import { DeviceStatus } from './device-info/deviceStatus';
import { ReportTime } from './device-info/reportTime';
import { IfaceStatus } from './interface-status/ifaceStatus';
import { ispUptimeStats } from './isp-uptime/ispUptimeStats';
import { MpSecStatus } from './mpsec-status/mpsecStatus';
import { IfaceDiagStats } from './interface-diagnostics/ifaceDiagStats';
import { WanDataRateInStats } from './device-wan-data-rate-inbound-chart/wanDataRateInStats';
import { WanDataRateOutStats } from './device-wan-data-rate-outbound-chart/wanDataRateOutStats';
import { InboundStats } from './device-bwutil-inbound-chart/inboundStats';
import { OutboundStats } from './device-bwutil-outbound-chart/outboundStats';
import { AppVisibilityStats } from './application-visibility-chart/appVisibilityStats';
import { LatencyStats } from './device-latency-chart/latencyStats';
import { JitterStats } from './device-jitter-chart/jitterStats';
import { PacketLossStats } from './device-packet-loss-chart/packetLossStats';
import { CpuMemStats } from './cpu-mem-chart/cpuMemStats';
import { WanOptIPs } from './wan-opt-stats-chart/wanOptIPs';
import { WanOptStats } from './wan-opt-stats-chart/wanOptStats';
import { ConversationStats } from './top-conversations/conversationStats';
import { DiskUsageStats } from '../statistics/system/diskUsageStats';
import { WanDataRateAllStats } from '../statistics/wan-data-rate/wanDataRateAllStats';
import { InterfaceAllStats } from '../statistics/interface/interfaceAllStats';
import { TunnelIPs } from '../statistics/tunnel/tunnelIPs';
import { TunnelStatus } from '../statistics/tunnel/tunnelStatus';
import { TunnelLatency } from '../statistics/tunnel/tunnelLatency';
import { TunnelJitter } from '../statistics/tunnel/tunnelJitter';
import { TunnelPktLoss } from '../statistics/tunnel/tunnelPktLoss';
import { AHStats } from '../statistics/interface/ahStats';
import { ESPStats } from '../statistics/interface/espStats';
import { GREStats } from '../statistics/interface/greStats';
import { ICMPStats } from '../statistics/interface/icmpStats';
import { TCPStats } from '../statistics/interface/tcpStats';
import { UDPStats } from '../statistics/interface/udpStats';
import { IfaceStats } from '../statistics/interface/ifaceStats';
import { IPSecStatus } from '../statistics/ipsec/ipsecStatus';
import { IPSecTunnel } from '../statistics/ipsec/ipsecTunnel';
import { IPSecTunnelStatus } from '../statistics/ipsec/ipsecTunnelStatus';
import { QosRules } from '../statistics/qos/qosRules';
import { QosTrafficInbound } from '../statistics/qos/qosTrafficInbound';
import { QosTrafficOutbound } from '../statistics/qos/qosTrafficOutbound';
import { QosLossInbound } from '../statistics/qos/qosLossInbound';
import { QosLossOutbound } from '../statistics/qos/qosLossOutbound';
import { AppPatternStats } from './application-usage-pattern/appPatternStats';
import { AppHostsStats } from '../statistics/application/appHostsStats';
import { AppProtocolsStats } from '../statistics/application/appProtocolsStats';
import { MpSecQosRules } from '../statistics/mpsec-qos/mpsecQosRules';
import { MpSecQosSites } from '../statistics/mpsec-qos/mpsecQosSites';
import { MpSecQosLocalIPs } from '../statistics/mpsec-qos/mpsecQosLocalIPs';
import { MpSecQosRemoteIPs } from '../statistics/mpsec-qos/mpsecQosRemoteIPs';
import { MpSecQosTrafficInbound } from '../statistics/mpsec-qos/mpsecQosTrafficInbound';
import { MpSecQosTrafficOutbound } from '../statistics/mpsec-qos/mpsecQosTrafficOutbound';
import { MpSecQosLossInbound } from '../statistics/mpsec-qos/mpsecQosLossInbound';
import { MpSecQosLossOutbound } from '../statistics/mpsec-qos/mpsecQosLossOutbound';
import { AppTableStats } from '../statistics/application/appTableStats';
import { AppBandWidth } from '../statistics/application/appBandWidth';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private hostname = window.location.host;

  currentDevice = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) { }

  getAllDevices(): Observable<DeviceInfo[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/devices/all";
    let items = this.http.get<DeviceInfo[]>(tempURL, { headers: JSONutils.getHeaders() });
    return items;
  }

  getDeviceInfo(): Observable<DeviceInfo[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/devices/" + this.currentDevice.value;
    return this.http.get<DeviceInfo[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getTextAlerts(): Observable<TextAlert[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/alertlog/" + this.currentDevice.value + "/all";
    return this.http.get<TextAlert[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getDeviceStatus(): Observable<DeviceStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/devicestatus/data/" + this.currentDevice.value + "/status";
    return this.http.get<DeviceStatus[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getLastReportTime(): Observable<ReportTime> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/devicestatus/data/" + this.currentDevice.value + "/lastreptime";
    return this.http.get<ReportTime>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getSysUptime(): Observable<SystemUptime[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/systemstats/data/" + this.currentDevice.value + "/uptime";
    return this.http.get<SystemUptime[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getInterfaceStatus(interval: string): Observable<IfaceStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/ifacestatus";
    return this.http.get<IfaceStatus[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getISPUptime(interval: string): Observable<ispUptimeStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/ispuptime";
    return this.http.get<ispUptimeStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getTunnelStats(interval: string): Observable<MpSecStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpathstats";
    return this.http.get<MpSecStatus[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getWanDataRateInboundStats(interval: string): Observable<WanDataRateInStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/wandataratestats/data/" + this.currentDevice.value + "/indatarate";
    return this.http.get<WanDataRateInStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getWanDataRateOutboundStats(interval: string): Observable<WanDataRateOutStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/wandataratestats/data/" + this.currentDevice.value + "/outdatarate";
    return this.http.get<WanDataRateOutStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getInboundStats(interval: string): Observable<InboundStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/bwutil/inbound";
    return this.http.get<InboundStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getOutboundStats(interval: string): Observable<OutboundStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/bwutil/outbound";
    return this.http.get<OutboundStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getAppVisibleStats(interval: string): Observable<AppVisibilityStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/usage";
    return this.http.get<AppVisibilityStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('limit', '15') });
  }

  getAppPatternStats(interval: string): Observable<AppPatternStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/patterns";
    return this.http.get<AppPatternStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getLatencyStats(interval: string): Observable<LatencyStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/latency";
    return this.http.get<LatencyStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getJitterStats(interval: string): Observable<JitterStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/jitter";
    return this.http.get<JitterStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getPacketLossStats(interval: string): Observable<PacketLossStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/packetloss";
    return this.http.get<PacketLossStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getCPUMemStats(interval: string): Observable<CpuMemStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/systemstats/data/" + this.currentDevice.value + "/all";
    return this.http.get<CpuMemStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getWanOptIps(): Observable<WanOptIPs[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/wanoptstats/data/" + this.currentDevice.value + "/remotesites";
    return this.http.get<WanOptIPs[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getWanOptStats(interval: string, ip_address: string): Observable<WanOptStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/wanoptstats/data/" + this.currentDevice.value + "/wanoptstats";
    return this.http.get<WanOptStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('ip_address', ip_address) });
  }

  getIfaceDiagStats(interval: string): Observable<IfaceDiagStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/ifacediagstats/data/" + this.currentDevice.value + "/ifacediagstats";
    return this.http.get<IfaceDiagStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getTopConversations(interval: string): Observable<ConversationStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/sessions";
    return this.http.get<ConversationStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }





  // Statistics System
  getDiskUsage(): Observable<DiskUsageStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/systemstats/data/" + this.currentDevice.value + "/diskusage";
    return this.http.get<DiskUsageStats[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  // Statistics WAN Data Rate
  getWanDataRateAllStats(interval: string): Observable<WanDataRateAllStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/wandataratestats/data/" + this.currentDevice.value + "/details";
    return this.http.get<WanDataRateAllStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  // Statistics Interface
  getInterfaceAllStats(interval: string): Observable<InterfaceAllStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/details";
    return this.http.get<InterfaceAllStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getTCPStats(interval: string): Observable<TCPStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/tcpcount";
    return this.http.get<TCPStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getUDPStats(interval: string): Observable<UDPStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/udpcount";
    return this.http.get<UDPStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getESPStats(interval: string): Observable<ESPStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/espcount";
    return this.http.get<ESPStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getGREStats(interval: string): Observable<GREStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/grecount";
    return this.http.get<GREStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getICMPStats(interval: string): Observable<ICMPStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/icmpcount";
    return this.http.get<ICMPStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getAHStats(interval: string): Observable<AHStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/ahcount";
    return this.http.get<AHStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getIfaceStats(interval: string): Observable<IfaceStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/interfacestats/data/" + this.currentDevice.value + "/ispstatus";
    return this.http.get<IfaceStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  // Statistics Application
  getAppVisibleAllStats(interval: string): Observable<AppVisibilityStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/usage";
    return this.http.get<AppVisibilityStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getAppVisibleAllStatsUpload(interval: string): Observable<AppBandWidth[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/uploadbw";
    return this.http.get<AppBandWidth[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }
    
  getAppVisibleAllStatsDownload(interval: string): Observable<AppBandWidth[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/downloadbw";
    return this.http.get<AppBandWidth[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }
 
  getAppHostsStats(interval: string): Observable<AppHostsStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/hosts";
    return this.http.get<AppHostsStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getAppProtocolStats(interval: string): Observable<AppProtocolsStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/protocols";
    return this.http.get<AppProtocolsStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval) });
  }

  getAppTable(interval: string, rows: string): Observable<AppTableStats[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/sessionstats/data/" + this.currentDevice.value + "/details";
    return this.http.get<AppTableStats[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rows', rows) });
  }

  // Statistics MPSec Overlay
  getTunnelRemoteIPs(): Observable<TunnelIPs[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpaths";
    return this.http.get<TunnelIPs[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getTunnelConnectionStatus(interval: string, remote_ip: string): Observable<TunnelStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpath/status";
    return this.http.get<TunnelStatus[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('remoteip', remote_ip) });
  }

  getTunnelLatency(interval: string, remote_ip: string): Observable<TunnelLatency[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpath/latency";
    return this.http.get<TunnelLatency[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('remoteip', remote_ip) });
  }

  getTunnelJitter(interval: string, remote_ip: string): Observable<TunnelJitter[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpath/jitter";
    return this.http.get<TunnelJitter[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('remoteip', remote_ip) });
  }

  getTunnelPktLoss(interval: string, remote_ip: string): Observable<TunnelPktLoss[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecpathstats/data/" + this.currentDevice.value + "/mpsecpath/pktloss";
    return this.http.get<TunnelPktLoss[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('remoteip', remote_ip) });
  }

  // Statistics IPSec Tunnel
  getIPSecInfo(): Observable<IPSecStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/ipsectunnelstats/data/" + this.currentDevice.value + "/tunnelstats";
    return this.http.get<IPSecStatus[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', "1 minutes") });
  }

  getIPSecTunnels(): Observable<IPSecTunnel[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/ipsectunnelstats/data/" + this.currentDevice.value + "/tunnels";
    return this.http.get<IPSecTunnel[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getIPSecTunnelStatus(interval: string, tunnelName: string): Observable<IPSecTunnelStatus[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/ipsectunnelstats/data/" + this.currentDevice.value + "/tunnels/status";
    return this.http.get<IPSecTunnelStatus[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('tunnelname', tunnelName) });
  }

  // Statistics QoS
  getQosRules(): Observable<QosRules[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/qosstats/data/" + this.currentDevice.value + "/rules";
    return this.http.get<QosRules[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getQosTrafficInbound(interval: string, rulename: string): Observable<QosTrafficInbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/qosstats/data/" + this.currentDevice.value + "/traffic/inbound";
    return this.http.get<QosTrafficInbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rulename) });
  }

  getQosTrafficOutbound(interval: string, rulename: string): Observable<QosTrafficOutbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/qosstats/data/" + this.currentDevice.value + "/traffic/outbound";
    return this.http.get<QosTrafficOutbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rulename) });
  }

  getQosLossInbound(interval: string, rulename: string): Observable<QosLossInbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/qosstats/data/" + this.currentDevice.value + "/loss/inbound";
    return this.http.get<QosLossInbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rulename) });
  }

  getQosLossOutbound(interval: string, rulename: string): Observable<QosLossOutbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/qosstats/data/" + this.currentDevice.value + "/loss/outbound";
    return this.http.get<QosLossOutbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rulename) });
  }

  // Statistics MPSec QoS
  getMPSecQosRules(): Observable<MpSecQosRules[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/rules";
    return this.http.get<MpSecQosRules[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getMPSecQosSites(): Observable<MpSecQosSites[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/sites";
    return this.http.get<MpSecQosSites[]>(tempURL, { headers: JSONutils.getHeaders() });
  }

  getMPSecQosLocal(rulename: string): Observable<MpSecQosLocalIPs[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/local";
    return this.http.get<MpSecQosLocalIPs[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('rulename', rulename) });
  }

  getMPSecQosRemote(rulename: string): Observable<MpSecQosRemoteIPs[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/remote";
    return this.http.get<MpSecQosRemoteIPs[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('rulename', rulename) });
  }

  getMPSecQosTrafficIn(interval: string, rule: string, site: string, localIP: string, remoteIP: string): Observable<MpSecQosTrafficInbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/traffic/inbound";
    return this.http.get<MpSecQosTrafficInbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rule).set('sitename', site).set('localwan', localIP).set('remotewan', remoteIP) });
  }

  getMPSecQosTrafficOut(interval: string, rule: string, site: string, localIP: string, remoteIP: string): Observable<MpSecQosTrafficOutbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/traffic/outbound";
    return this.http.get<MpSecQosTrafficOutbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rule).set('sitename', site).set('localwan', localIP).set('remotewan', remoteIP) });
  }

  getMPSecQosLossIn(interval: string, rule: string, site: string, localIP: string, remoteIP: string): Observable<MpSecQosLossInbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/loss/in";
    return this.http.get<MpSecQosLossInbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rule).set('sitename', site).set('localwan', localIP).set('remotewan', remoteIP) });
  }

  getMPSecQosLossOut(interval: string, rule: string, site: string, localIP: string, remoteIP: string): Observable<MpSecQosLossOutbound[]> {
    let tempURL = "https://" + this.hostname + "/fp/api/v1/mpsecqosstats/data/" + this.currentDevice.value + "/loss/out";
    return this.http.get<MpSecQosLossOutbound[]>(tempURL, { headers: JSONutils.getHeaders(), params: new HttpParams().set('interval', interval).set('rulename', rule).set('sitename', site).set('localwan', localIP).set('remotewan', remoteIP) });
  }


}
