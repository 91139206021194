<nb-layout>
  <nb-layout-column class="columnsp">
    <div class="row">
      <div class="col-sm-0 col-md-2 col-lg-3 col-xl-4">
        <!--Filler Space-->
      </div>
      <div class="col-sm-12 col-md-8 col-lg-6 col-xl-4">
        <nb-card>
          <nb-card-body>
            <div align="center">
              <nb-auth-block>
                <div align="center"><img src="assets/images/fatpipelogo.png"></div>

                <nb-alert *ngIf="showMessages.error && errors!=null && errors?.length && !submitted" outline="danger" role="alert">
                  <p class="alert-title"><b>Oh snap!</b></p>
                  <ul class="alert-message-list">
                    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
                  </ul>
                </nb-alert>

                <nb-alert *ngIf="showMessages.success && messages!=null && messages?.length && !submitted" outline="success" role="alert">
                  <p class="alert-title"><b>Hooray!</b></p>
                  <ul class="alert-message-list">
                    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
                  </ul>
                </nb-alert>

                <form (ngSubmit)="onSubmit()" #form="ngForm" aria-labelledby="title">

                  <div class="form-control-group">
                    <label class="label" for="input-Username">Email:</label>
                    <input nbInput fullWidth [(ngModel)]="user.name" #fullName="ngModel" name="fullName" id="input-username"
                           placeholder="Email Address" autofocus autocomplete="username"
                           [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : 'basic'"
                           [required]="getConfigValue('forms.validation.fullName.required')"
                           [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null">
                    <div class="alert-text" *ngIf="fullName.invalid && fullName.touched">
                      <div *ngIf="fullName.errors?.required">Please enter an Email Address</div>
                    </div>
                  </div>

                  <div class="form-control-group">
                    <label class="label" for="input-password">Password:</label>
                    <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password"
                           id="input-password" placeholder="Password" autocomplete="current-password"
                           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                           [required]="getConfigValue('forms.validation.password.required')"
                           [minlength]="getConfigValue('forms.validation.password.minLength')"
                           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
                    <div class="alert-text" *ngIf="password.invalid && password.touched">
                      <div *ngIf="password.errors?.required">Please enter a Password</div>
                      <div *ngIf="password.errors?.minlength || password.errors?.maxlength">
                        Password should be {{ getConfigValue('forms.validation.password.minLength') }} to {{ getConfigValue('forms.validation.password.maxLength') }} characters
                      </div>
                    </div>
                  </div>

                  <button nbButton fullWidth status="success" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="medium">Log In</button>
                </form>

              </nb-auth-block>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-sm-0 col-md-2 col-lg-3 col-xl-4">
        <!--Filler Space-->
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
